<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Cierre de Kardex</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>


               <b-col md="3">
                  <b-form-group label="Establecimiento:">
                    <b-form-select v-model="close_kardex.id_establishment" :options="establishments"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Tipo Comprobante:">
                    <b-form-select v-model="close_kardex.type_invoice" :options="type_invoice"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Fecha Emision:">
                    <b-form-input class="text-center" type="date" ref="broadcast_date" v-model="close_kardex.broadcast_date"></b-form-input>
                    <small v-if="errors.broadcast_date" class="form-text text-danger">Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label=".">
                    <b-button class="form-control" @click="CloseKardex" variant="primary">Cerrar Kardex</b-button>
                  </b-form-group>
                </b-col>

               
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>
<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import { mapState,mapActions } from "vuex";
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  components:{
      LoadingComponent,
  },
  data() {
    return {
      isLoading:false,
      module: 'CloseKardex',
      role: 2,
      file_excel:null,
      file_excel_name:'',
      close_kardex: {
        id_establishment: "all",
        type_invoice: "NE",
        broadcast_date: moment(new Date()).local().format("YYYY-MM-DD"),
        observation: "",
      },
      establishments : [],
      type_invoice:[
        {value: "NE", text : "Nota de Entrada"},
      ],
      //errors
      errors: {
        id_establishment: false,
        type_invoice: false,
        broadcast_date: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListEstablishment();
  },
  methods: {
    CloseKardex,
    ListEstablishment,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};


function ListEstablishment() {

  let me = this;
  let url = this.url_base + "active-establishments";

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,},
  })
    .then(function (response) {
      me.establishments = [{ value: 'all', text:'- Todos -'}];
      if (response.data.status == 200) {
        let data = response.data.result;
        for (let index = 0; index < data.length; index++) {
          me.establishments.push( { value : data[index].id_establishment , text: data[index].name } );
        }
      }
    })
}

function CloseKardex() {
  
  if (this.close_kardex.id_establishment.length == 0) {
    Swal.fire({ icon: 'warning', text: 'Seleccione un establecimiento', timer: 2000,})
    return false;
  }

  if (this.close_kardex.broadcast_date.length == 0) {
    Swal.fire({ icon: 'warning', text: 'Seleccione un fecha', timer: 2000,})
    return false;
  }
  
  let url = this.url_base + "close-kardex/"+this.close_kardex.id_establishment+"/"+this.close_kardex.type_invoice+"/"+this.close_kardex.broadcast_date;
  window.open(url,'_blank');

}
</script>
